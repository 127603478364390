/*************************************************************
 * jahan.engineer
 *
 *   Stylesheet
 *   - Design inspired by the simplematic theme for webflow
 *
 **************************************************************/

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
html {
  height: 100%;
}
body {
  margin: 0;
  min-height: 100%;
  background-color: #fff;
  font-family: Arial, sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: #333;
}
img {
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
}
.w-inline-block {
  max-width: 100%;
  display: inline-block;
}
h1,
h2,
h3,
h4 {
  font-weight: bold;
  margin-bottom: 10px;
}
h1 {
  font-size: 38px;
  line-height: 44px;
  margin-top: 20px;
}
h2 {
  font-size: 32px;
  line-height: 36px;
  margin-top: 20px;
}
h3 {
  font-size: 24px;
  line-height: 30px;
  margin-top: 20px;
}
h4 {
  font-size: 18px;
  line-height: 24px;
  margin-top: 10px;
}
p {
  margin-top: 0;
  margin-bottom: 10px;
}
ul {
  margin-top: 0px;
  margin-bottom: 10px;
  padding-left: 40px;
}
.w-container {
  margin-left: auto;
  margin-right: auto;
  max-width: 940px;
}
.w-container:before,
.w-container:after {
  content: " ";
  display: table;
  grid-column-start: 1;
  grid-row-start: 1;
  grid-column-end: 2;
  grid-row-end: 2;
}
.w-container:after {
  clear: both;
}
@media screen and (max-width: 991px) {
  .w-container {
    max-width: 728px;
  }
}
@media screen and (max-width: 479px) {
  .w-container {
    max-width: none;
  }
}
.w-richtext:before,
.w-richtext:after {
  content: " ";
  display: table;
  grid-column-start: 1;
  grid-row-start: 1;
  grid-column-end: 2;
  grid-row-end: 2;
}
.w-richtext:after {
  clear: both;
}
.w-richtext ul {
  overflow: hidden;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

   .w-layout-grid {
     display: -ms-grid;
     display: grid;
     grid-auto-columns: 1fr;
     -ms-grid-columns: 1fr 1fr;
     grid-template-columns: 1fr 1fr;
     -ms-grid-rows: auto auto;
     grid-template-rows: auto auto;
     grid-row-gap: 16px;
     grid-column-gap: 16px;
   }

   body {
     font-family: Inter, sans-serif;
     color: #666;
     font-size: 14px;
     line-height: 1.714em;
     font-weight: 400;
   }

   h1 {
     margin-top: 0px;
     margin-bottom: 8px;
     color: #141414;
     font-size: 36px;
     line-height: 1.278em;
     font-weight: 500;
   }

   h2 {
     margin-top: 0px;
     margin-bottom: 8px;
     color: #141414;
     font-size: 26px;
     line-height: 1.385em;
     font-weight: 500;
   }

   h3 {
     margin-top: 0px;
     margin-bottom: 6px;
     color: #141414;
     font-size: 22px;
     line-height: 1.455em;
     font-weight: 500;
   }

   h4 {
     margin-top: 0px;
     margin-bottom: 10px;
     color: #141414;
     font-size: 18px;
     line-height: 1.556em;
     font-weight: 500;
   }

   p {
     margin-bottom: 12px;
   }

   a {
     transition: color 300ms ease;
     color: #141414;
     text-decoration: underline;
   }

   a:hover {
     color: #8d8d8d;
   }

   ul {
     margin-top: 0px;
     margin-bottom: 10px;
     padding-left: 40px;
   }

   li {
     margin-bottom: 8px;
     padding-left: 8px;
   }

   img {
     display: inline-block;
     max-width: 100%;
   }

   .page-wrapper {
     -ms-grid-row-align: stretch;
     align-self: stretch;
   }

   .grid-2-columns {
     display: -ms-grid;
     display: grid;
     align-items: center;
     grid-auto-columns: 1fr;
     grid-column-gap: 32px;
     grid-row-gap: 32px;
     -ms-grid-columns: 1fr 1fr;
     grid-template-columns: 1fr 1fr;
     -ms-grid-rows: auto;
     grid-template-rows: auto;
   }

   .grid-2-columns.image-and-paragraph {
     -ms-grid-columns: auto 1fr;
     grid-template-columns: auto 1fr;
   }

   .grid-2-columns.sidebar-right {
     align-items: start;
     -ms-grid-columns: 1fr auto;
     grid-template-columns: 1fr auto;
   }

   .divider {
     height: 1px;
     margin-top: 80px;
     margin-bottom: 80px;
     background-color: #e8e8e8;
   }

   .divider._0px {
     margin-top: 0px;
     margin-bottom: 0px;
   }

   .mg-bottom-16px {
     margin-bottom: 16px;
   }

   .mg-bottom-24px {
     margin-bottom: 24px;
   }

   .grid-1-column {
     display: -ms-grid;
     display: grid;
     grid-auto-columns: 1fr;
     -ms-grid-columns: 1fr;
     grid-template-columns: 1fr;
     -ms-grid-rows: auto;
     grid-template-rows: auto;
   }

   .gap-row-24px {
     grid-row-gap: 24px;
   }

   .text-300 {
     font-size: 18px;
     line-height: 1.111em;
   }

   .text-300.bold {
     font-weight: 600;
   }

   .text-100 {
     font-size: 14px;
     line-height: 1.143em;
   }

   .text-100.bold {
     font-weight: 600;
   }

   .inner-container._370px {
     max-width: 370px;
   }

   .inner-container._438px {
     max-width: 438px;
   }

   .mg-bottom-0 {
     margin-bottom: 0px;
   }

   .avatar-circle {
     overflow: hidden;
     width: 100%;
     border-radius: 50%;
     transform: translate(0px, 0px);
   }

   .avatar-circle._07 {
     max-width: 200px;
   }

   .container-default {
     max-width: 792px;
     padding-right: 24px;
     padding-left: 24px;
   }

   .section {
     width: 100%;
     padding-top: 44px;
     padding-bottom: 44px;
   }

   .link-wrapper {
     display: inline-block;
     color: #141414;
     line-height: 1.143em;
     font-weight: 500;
   }

   .link-wrapper:hover {
     color: #141414;
   }

   .link-text {
     display: inline-block;
     transition: color 300ms ease;
   }

   .heading-h1-size {
     color: #141414;
     font-size: 36px;
     line-height: 1.278em;
     font-weight: 500;
   }

   .center-element {
     margin-right: auto;
     margin-left: auto;
   }

   .mg-bottom-6px {
     margin-bottom: 6px;
   }

   .text-no-wrap {
     white-space: nowrap;
   }

   .divider-wrapper {
     -ms-grid-row-align: stretch;
     align-self: stretch;
   }

   .body {
     display: flex;
     flex-direction: column;
     align-items: center;
   }

   .title-dark-mode {
     color: #141414;
   }

   @media screen and (max-width: 991px) {
     h2 {
       margin-bottom: 12px;
     }

     .divider {
       margin-top: 64px;
       margin-bottom: 64px;
     }
   }

   @media screen and (max-width: 767px) {
     h1 {
       margin-bottom: 10px;
       font-size: 30px;
     }

     h2 {
       margin-bottom: 8px;
       font-size: 24px;
     }

     h3 {
       font-size: 20px;
     }

     .grid-2-columns {
       -ms-grid-columns: 1fr;
       grid-template-columns: 1fr;
     }

     .grid-2-columns.image-and-paragraph {
       -ms-grid-columns: auto;
       grid-template-columns: auto;
     }

     .grid-2-columns.sidebar-right {
       grid-row-gap: 60px;
       -ms-grid-columns: 1fr;
       grid-template-columns: 1fr;
     }

     .divider {
       margin-top: 48px;
       margin-bottom: 48px;
     }

     .text-300 {
       font-size: 18px;
       line-height: 20px;
     }

     .text-100 {
       font-size: 14px;
     }

     .inner-container._432px-mbl {
       max-width: 432px;
     }

     .avatar-circle._07 {
       max-width: 160px;
     }

     .section {
       padding-top: 53px;
       padding-bottom: 53px;
     }

     .heading-h1-size {
       font-size: 30px;
     }

     .text-center-mbl {
       text-align: center;
     }
   }

   @media screen and (max-width: 479px) {
     h1 {
       font-size: 25px;
     }

     h2 {
       font-size: 20px;
     }

     h3 {
       font-size: 18px;
     }

     h4 {
       font-size: 16px;
     }

     .divider {
       margin-top: 32px;
       margin-bottom: 32px;
     }

     .container-default {
       padding-right: 20px;
       padding-left: 20px;
     }

     .section {
       padding-top: 44px;
       padding-bottom: 44px;
     }

     .heading-h1-size {
       font-size: 25px;
     }
   }

   @media screen and (max-width: 767px) {

     #w-node-_56b05d5e-907f-85f2-5a8d-f19f33d3fb4a-f77e58e2 {
       -ms-grid-column-align: center;
       justify-self: center;
     }
   }

   @font-face {
    font-family: 'icomoon';
    src:  url('fonts/icomoon.eot?ttzs82');
    src:  url('fonts/icomoon.eot?ttzs82#iefix') format('embedded-opentype'),
      url('fonts/icomoon.ttf?ttzs82') format('truetype'),
      url('fonts/icomoon.woff?ttzs82') format('woff'),
      url('fonts/icomoon.svg?ttzs82#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }

  [class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .icon-twitter:before {
    content: "\ea96";
  }
  .icon-linkedin2:before {
    content: "\eaca";
  }

  .social-icons a {
    text-decoration: none;
}
.social-icons {
    text-decoration: none;
    font-size: 19px;
}